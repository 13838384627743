
import './App.css';

import OurServices from './components/OurServices/OurServices';

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from './Layout/Main';
import MainAbout from './Layout/MainAbout';
import MainEvents from './Layout/MainEvents';
import NotFound from './components/NotFound/NotFound';
import Agents from './components/Agents/Agents';
import Registration from './components/Registration/Registration';
import Universities from './components/Universities/Universities';
import ContactUs from './components/ContactUs/ContactUs';
import Blogs from './components/Blogs/Blogs';
import GalleryFull from './components/GalleryFull/GalleryFull';
import Career from './components/Career/Career';
import ParantBlog from './AllBlogs/ParentBlog/ParantBlog';
import BlogOne from './AllBlogs/BlogOne/BlogOne';
import BlogTwo from './AllBlogs/BlogTwo/BlogTwo';
// import BlogThree from './AllBlogs/BlogThree/BlogThree';
import BlogFour from './AllBlogs/BlogFour/BlogFour';
import OfficeAll from './components/OfficeAll/OfficeAll';
import { useEffect } from 'react';
import Scolarship from './components/Scolarship/Scolarship';
import ThankYou from './components/ThankYou/ThankYou';
import CareerCards from './components/CareerCards/CareerCards';
import RegistrationOne from './components/RegistrationOne/RegistrationOne';
import ThankYouFb from './components/ThankYouFb/ThankYouFb';
import Registration2 from './RegistrationAll/Registration2';
import Registration3 from './RegistrationAll/Registration3';
import Registration4 from './RegistrationAll/Registration4';
import Registration5 from './RegistrationAll/Registration5';
import Registration6 from './RegistrationAll/Registration6';
import Registration7 from './RegistrationAll/Registration7';
import Registration8 from './RegistrationAll/Registration8';
import Registration9 from './RegistrationAll/Registration9';
import Registration10 from './RegistrationAll/Registration10';
import Registration11 from './RegistrationAll/Registration11';
import NewReg1 from './RegistrationAll/NewReg/NewReg1';
import NewReg2 from './RegistrationAll/NewReg/NewReg2';
import NewReg3 from './RegistrationAll/NewReg/NewReg3';
import NewReg4 from './RegistrationAll/NewReg/NewReg4';
import NewReg5 from './RegistrationAll/NewReg/NewReg5';
import PrivacyPolicy from './Shared/PrivacyPolicy/PrivacyPolicy';
import IcefReg from './RegistrationAll/IcefReg/IcefReg';

// import Webmail from './Shared/Webmail/Webmail';
// import Gallery from './components/Gallery/Gallery';z
// import Gallery from './components/Gallery/Gallery';z
// import Gallery from './components/Gallery/Gallery';z

function App() {



  const router = createBrowserRouter([
    { path: '/', element: <Main></Main> },
    { path: '/home', element: <Main></Main> },
    { path: '/services', element: <OurServices></OurServices> },
    { path: '/about', element: <MainAbout></MainAbout> },
    { path: '/events', element: <MainEvents></MainEvents> },
    { path: '/ChannelPartner', element: <Agents></Agents> },
    { path: '/Universities', element: <Universities></Universities> },
    { path: '/contact', element: <ContactUs></ContactUs> },
    { path: '/photos', element: <GalleryFull></GalleryFull> },
    { path: '/scholarship', element: <Scolarship></Scolarship> },
    { path: '/ouroffice', element: <OfficeAll></OfficeAll> },
    { path: '/career', element: <CareerCards/>},
    { path: '/blogs', element: <ParantBlog></ParantBlog>},
    { path: '/blogone', element: <BlogFour/>},
    { path: '/blogtwo', element: <BlogTwo></BlogTwo>},
    // { path: '/blogthree', element: <BlogThree></BlogThree>},
    { path: '/ThankYou', element: <ThankYou/>},
    { path: '/ThankYoufb', element: <ThankYouFb/>},
    { path: '/wearehiring1', element: <Career/>},
    { path: '/privacy-policy', element: <PrivacyPolicy/>},
    // allregistration links
    { path: '/registration', element: <Registration ></Registration> },
    { path: '/fbregistration', element: <RegistrationOne/> },
    { path: '/ctgregistration', element: <Registration2/> },
    { path: '/dhkregistration', element: <Registration3/> },
    { path: '/sylregistration', element: <Registration4/> },
    { path: '/ceone', element: <Registration5/> },
    { path: '/cetwo', element: <Registration6/> },
    { path: '/ctgevent', element: <Registration7/> },
    { path: '/dhkevent', element: <Registration8/> },
    { path: '/sylevent', element: <Registration9/> },
    { path: '/unievent1', element: <Registration10/> },
    { path: '/unievent2', element: <Registration11/> },
    { path: '/ICEFLondon', element: <IcefReg/> },
  
    // rabbani er blog
    { path: '/Strategies-for-Securing-Admission-in-Top-UK-Universities', element: <NewReg1/> },
    { path: '/welcome-to-study-london', element: <NewReg2/> },
    { path: '/welcome-to-study-in-the-uk', element: <NewReg3/> },
    { path: '/undergraduate-admission-guide', element: <NewReg4/> },
    { path: '/postgraduate-admissions-guide', element: <NewReg5/> },
    // { path: '/webmail', element: <Webmail/>},
    { path: '*', element: <NotFound></NotFound> },
  ])
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);




  return (
    <div >
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;

