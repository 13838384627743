import React, { useEffect } from 'react';
import bg from '../../assets/blogBg.jpg'
import Footer from '../../components/Footer/Footer';
import HeaderNav from '../../components/HeaderNav/HeaderNav';

import { useState } from 'react';
const NewReg2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    }


    return (
        <div>
            <HeaderNav></HeaderNav>

            {/* <FormRegistration/> */}
            {/* this is for test */}
            {/* <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%', overflow: 'hidden' }}>
                <iframe
                    src="https://app.shabujglobal.com/registration/student"
                    style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                >
                    Loading…
                </iframe>
            </div> */}


            {/* CRM link */}
            <div style={{ position: 'relative', width: '100%', height: '3050px', paddingBottom: '56.25%', marginTop: '80px' }}>
                {loading && (
                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
                        <p>Loading.....</p>
                    </div>
                )}
                <iframe
                    src="https://oldcrm.shabujglobal.com/welcome-to-study-in-the-uk"
                    scrolling="no"
                    style={{ position: 'absolute', width: '100%', height: '3050px', left: 0, top: 0 }}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    onLoad={handleLoad}

                >
                    Loading…
                </iframe>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default NewReg2;